module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5QBCPHF","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-rudderstack/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"2lFZzMZDmh0eamxO0HvW5TVR5i8","devKey":"2kO01LzcZlOv7DzZcPN6sA3RcEg","trackPage":true,"trackPageDelay":50,"dataPlaneUrl":"https://rs-dataplane.splashtop.com","delayLoad":false,"delayLoadTime":1000,"manualLoad":false,"sdkURL":"https://rs-cdn.splashtop.com/v3","loadType":"async","loadOptions":{"configUrl":"https://rs-api.splashtop.com"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
